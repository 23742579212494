import React from 'react'
import Layout from '@components/layouts/default/Layout'
import ServicesWrapper from '@components/ui/wrappers/ServicesWrapper'
import { injectIntl, Link } from 'gatsby-plugin-react-intl'
import { OpenModalContext } from '@components/layouts/default/Layout'
import modalContactTypes from '@configs/modalContactTypes'

const AccessibilityPolicy = ({ intl }) => {
  return (
    <Layout pageName="accessibility-policy">
      <OpenModalContext.Consumer>
        {(value) => {
          return (
            <ServicesWrapper>
              <h1>Accessibility Policy</h1>
              <p className="marginBottom">
                At IdeaBuddy, we are committed to ensuring our platform is accessible to everyone, including people with
                disabilities. This policy outlines the steps we have taken to make our website and services accessible
                and how we strive to continuously improve accessibility.
              </p>
              <h2>Commitment to accessibility</h2>
              <p className="marginBottom">
                We aim to provide an inclusive experience where all users can access and use our website effectively.
                Our accessibility practices are in alignment with web content accessibility standards, such as WCAG (Web
                Content Accessibility Guidelines). For more detail, our Voluntary Product Accessibility Template (VPAT)
                is available by request.
              </p>
              <h2>How we achieve accessibility</h2>
              <ul className="paddingVertical">
                <li className="marginTop">
                  Web design: We design our website with accessibility in mind, ensuring it is easy to navigate and
                  readable for users with various needs.
                </li>
                <li>
                  Assistive technologies: Our platform is compatible with screen readers and other assistive
                  technologies.
                </li>
                <li>
                  Keyboard navigation: We provide full keyboard navigation for users who may have difficulty using a
                  mouse.
                </li>
                <li>
                  Contrast and readability: We ensure high contrast between text and background and use simple fonts for
                  easier reading.
                </li>
              </ul>

              <h2>Ongoing improvements</h2>

              <p>
                We continuously monitor the accessibility of our website and services, making updates and improvements
                based on feedback and technological advancements.
              </p>

              <h2>Feedback and assistance</h2>
              <p>
                We welcome user feedback about how to improve the accessibility of our platform. If you encounter any
                issues or need assistance, please get in touch with us at
                <span
                  onClick={() => {
                    value.openContact(modalContactTypes.CONTACT)
                  }}
                  className="spanLink leftElement"
                  aria-hidden="true"
                >
                  support@ideabuddy.com
                </span>
              </p>
              <h2>Third-party tools and services</h2>
              <p>
                While we strive for the best possible accessibility, some third-party tools and integrations may not be
                fully compliant. We are actively working with our partners to improve accessibility in these areas.
              </p>

              <h2>Contact information</h2>
              <p>
                If you have any questions about our accessibility efforts or require assistance, please email us at
                <span
                  onClick={() => {
                    value.openContact(modalContactTypes.CONTACT)
                  }}
                  className="spanLink leftElement"
                  aria-hidden="true"
                >
                  support@ideabuddy.com
                </span>
                .
              </p>
            </ServicesWrapper>
          )
        }}
      </OpenModalContext.Consumer>
    </Layout>
  )
}

export default injectIntl(AccessibilityPolicy)
